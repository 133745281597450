import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { H3, P } from '@styles/Typography';

import { Section, Wrapper } from '@components/Layout/styles';

import SEO from '@components/seo';
import AppointmentButton from '@components/Button/AppointmentButton';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/Appointment';
import CardList from '@components/CardList';
import InnerBanner from '@components/InnerBanner';
import Sidebar from '@components/Sidebars/Main';
import TitleSection from '@components/TitleSection';
import { ShadowCard } from '@components/Cards';

import { orderAndHideItems } from '@utils/filter';

import * as S from './_styles';

const drugsList = [
    "Actemra SC®",
    "Hyrimoz®",
    "Ajovy®",
    "Idacio®",
    "Amgevita®",
    "Kesimpta®",
    "Avonex®",
    "Orencia®",
    "Cimzia®",
    "Praluent®",
    "Clexane®",
    "Prolia®",
    "Copaxone®",
    "Repatha®",
    "Cosentyx®",
    "Simponi®",
    "Dupixent®",
    "Skyrizi®",
    "Emgality®",
    "Stelara SC®",
    "Enbrel®",
    "SYBRAVA®",
    "Erelzi®",
    "Taltz",
    "Evenity®",
    "Tremfya",
    "Evushled®",
    "Xgeva®",
    "Fasenra®",
    "Xilbrilada®",
    "Hadlima®",
    "Xolair®",
    "Humira®",
    "Hyqvia®"
];

const AtendimentoMovelPage = () => {
	const {
		drugs,
		howto,
		main,
		meta,
		pageData,
	} = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-mobile-services" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "mobile-services" } }) {
				nodes {
					metadata {
						description
						title
						banner {
							imgix_url
						}
					}
				}
			}

			main: allCosmicjsMobileServices(
				filter: { slug: { eq: "main-section" } }
			) {
				nodes {
					metadata {
						description
						title
						items {
							icon {
								imgix_url
							}
							title
							description
							show
							order
						}
					}
				}
			}

			howto: allCosmicjsMobileServices(filter: { slug: { eq: "howto" } }) {
				nodes {
					metadata {
						description
						title
						steps {
							icon {
								imgix_url
							}
							title
							description
						}
					}
				}
			}

			drugs: allCosmicjsMobileServices(
				filter: { slug: { eq: "mobile-services-drugs" } }
			) {
				nodes {
					metadata {
						description
						title
					}
				}
			}

			drugList: allCosmicjsDrugs {
				nodes {
					metadata {
						commercial_name
						is_mobile_service
					}
				}
			}
		}
	`);

	const {
		description: mainDescription,
		items: mainItems,
		title: mainTitle,
	} = main.nodes[0].metadata;

	const {
		description: howtoDescription,
		title: howtoTitle,
		steps,
	} = howto.nodes[0].metadata;

	const {
		description: drugsDescription,
		title: drugsTitle,
	} = drugs.nodes[0].metadata;

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	const {
		metadata: { title: pageTitle, description: pageDescription, banner },
	} = pageData.nodes[0];

	const filteredMainItems = orderAndHideItems(mainItems);

	return (
		<>
			<SEO
				title={metaTitle || 'Atendimento móvel'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<InnerBanner
				title={pageTitle}
				description={pageDescription}
				bg={banner.imgix_url}
			/>
			<Wrapper>
				<Breadcrumb
					crumbs={[{ title: 'Atendimento móvel', slug: 'atendimento-movel' }]}
				/>
			</Wrapper>

			<Section>
				<Wrapper>
					<TitleSection title={mainTitle} content={mainDescription}>
						<AppointmentButton />
					</TitleSection>

					<CardList list={filteredMainItems} />
				</Wrapper>
			</Section>

			<Section bgColor="white">
				<Wrapper>
					<S.Block>
						<S.ContentBox>
							<H3>{howtoTitle}</H3>
							<P>{howtoDescription}</P>
						</S.ContentBox>

						<ShadowCard>
							{steps.map(
								({ title, icon: { imgix_url: icon }, description }, index) => (
									<S.HowToItem key={index} icon={icon}>
										<S.HowToInnerItem>
											<S.HowToTitle>{title}</S.HowToTitle>
											<div dangerouslySetInnerHTML={{ __html: description }} />
										</S.HowToInnerItem>
									</S.HowToItem>
								)
							)}
						</ShadowCard>
					</S.Block>

					<S.Block>
						<S.ContentBox>
							<H3>{drugsTitle}</H3>
							<P>{drugsDescription}</P>
						</S.ContentBox>

						<ShadowCard>
							<S.DrugsTitle>Medicamentos disponíveis</S.DrugsTitle>
							{/* <S.DrugList>
								{drugList.nodes.filter(
									({ metadata: { is_mobile_service } }) => is_mobile_service
								)
									.map(({ metadata: { commercial_name } }, index) => (
										<S.DrugItem key={index}>{commercial_name}</S.DrugItem>
									))}
							</S.DrugList> */}
							<S.DrugList>
							{drugsList.map((item, index) => {
								return <S.DrugItem key={index}>{item}</S.DrugItem>
							})}
							</S.DrugList>
						</ShadowCard>
					</S.Block>
				</Wrapper>
			</Section>

			<Section bgImg="https://imgix.cosmicjs.com/5f4b92e0-e254-11ea-ad85-b3e5c5f45697-726063e0-d39c-11ea-a151-53cec96789fd-national-cancer-institute-BxXgTQEw1M4-unsplash-1.jpg?q=&w=1920&h=600&fit=crop">
				<Wrapper>
					<Sidebar extended />
				</Wrapper>
			</Section>

			<Section>
				<Wrapper>
					<CTA noSpacing />
				</Wrapper>
			</Section>
		</>
	);
};

export default AtendimentoMovelPage;
